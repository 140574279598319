import React, { Component } from 'react';

import eureka from 'eureka';

import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-icons/dist/log.js';
import '@ui5/webcomponents-icons/dist/user-settings.js';
import { BusyIndicator } from '@ui5/webcomponents-react/dist/BusyIndicator';
import { Button } from '@ui5/webcomponents-react/dist/Button';
import { ButtonDesign } from '@ui5/webcomponents-react/dist/ButtonDesign';
import { PlacementType } from '@ui5/webcomponents-react/dist/PlacementType';
import { Popover } from '@ui5/webcomponents-react/dist/Popover';
import { PopoverHorizontalAlign } from '@ui5/webcomponents-react/dist/PopoverHorizontalAlign';
import { ShellBar } from '@ui5/webcomponents-react/dist/ShellBar';
import { ShellBarItem } from '@ui5/webcomponents-react/dist/ShellBarItem';
import { StandardListItem } from '@ui5/webcomponents-react/dist/StandardListItem';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { axiosCreate } from '../../axios/http-wrapper';
import { PXApp } from '../feedback';
import LogoutForm from '../plugins/LogoutForm';
import { getAvatar } from '../plugins/profile.plugin';
import CustomNotification from './CustomNotification';
import InfiniteScroll from './InfiniteScroll';
import logo from './logo';
import * as actions from './redux/actions';
import UserProfile from './user/UserProfile';

const { withTranslation } = eureka.I18nProvider;
const eventBus = eurekaMgrs.eventBus;
const { getFeatureToggle, addConfig } = eurekaMgrs.ConfigManager;

const MAX_NOTIFICATION_DISPLAY_NUMBER = 100;

class Header extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      logoutModalVisible: false,
      screenSize: document.documentElement.clientWidth,
      prevScreenSize: document.documentElement.clientWidth,
      isClearing: false,
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
    };

    this.notificationPopoverRef = React.createRef();
    this.profileMenuRef = React.createRef();
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.handleProfileClick = this.handleProfileClick.bind(this);

    this.openProfileSetting = this.openProfileSetting.bind(this);
    this.logout = this.logout.bind(this);

    this.dismissNotification = this.dismissNotification.bind(this);
    this.deleteAllNotification = this.deleteAllNotification.bind(this);
    this.loadMoreNotifications = this.loadMoreNotifications.bind(this);
    this.handleSidenav = this.handleSidenav.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);

    this.showPXBtnFeatureFlag = getFeatureToggle('ITCM.57381.Product.Experience.Management');
    this.myPXApp = null;
    if (this.showPXBtnFeatureFlag) {
      this.getPxInformation();
    }
  }

  componentDidMount() {
    this.props.actions.startWebsocket();
    if (
      document.documentElement.clientWidth <= 1349 ||
      localStorage.getItem('sideMenuManuallyCollapsed') === 'true'
    ) {
      this.props.actions.hideMenu();
    } else {
      this.props.actions.showMenu();
    }

    this.menuAutoEvent = debounce(() => {
      const collapsedManually = localStorage.getItem('sideMenuManuallyCollapsed') === 'true';
      if (!collapsedManually) {
        this.hideMenuAuto();
      }
    }, 500);
    window.addEventListener(
      'resize',
      this.menuAutoEvent,
      true,
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.menuAutoEvent, true);
  }

  hideMenuAuto = () => {
    //this.state.prevScreenSize - screen width before moving
    //this.state.screenSize - current setted screen size
    //document.documentElement.clientWidth - real screen size
    if (document.documentElement.clientWidth !== this.state.prevScreenSize) {
      //if previous screen size was more then 1349 and new screen size is less then 1349 then will collapse menu
      if (
        this.state.prevScreenSize > 1349 &&
        document.documentElement.clientWidth < 1349 &&
        this.props.common.showMenu
      ) {
        this.props.actions.hideMenu();
        //if previous screen size was less then 1349 and new screen size is more then 1349 then will expand menu
      } else if (
        this.state.prevScreenSize < 1349 &&
        document.documentElement.clientWidth > 1349 &&
        !this.props.common.showMenu
      ) {
        this.props.actions.showMenu();
      }
      //in all others cases (if we manually expand/collapse menu) we don't need to change state of nav menu

      //when resize stops then screenSize should be equal to prevScreenSize
      this.setState({
        screenSize: document.documentElement.clientWidth,
        prevScreenSize: document.documentElement.clientWidth,
      });
    }
  };

  handleNotificationClick(e) {
    this.closeProfileMenu();
    this.notificationPopoverRef.current.openBy(e.detail.targetRef);
  }

  handleProfileClick(e) {
    this.notificationPopoverRef.current.close();
    this.profileMenuRef.current.openBy(e.detail.targetRef);
  }

  openProfileSetting() {
    this.closeProfileMenu();
    const { config, t } = this.props;
    eventBus.emit('dialog-show-hide', '', {
      type: 'user-profile',
      data: {
        showDialog: true,
        title: t('ProfileSettings'),
        content: () => <UserProfile config={config} eventBus={eventBus} />,
      },
    });
  }

  logout() {
    this.closeProfileMenu();
    eventBus.emit('dialog-show-hide', '', {
      type: 'user-logout',
      data: {
        showDialog: true,
        content: <LogoutForm eventBus={eventBus} />,
      },
    });
  }

  closeProfileMenu() {
    this.profileMenuRef.current.close();
  }

  dismissNotification(notification) {
    this.props.actions.dismissNotification(notification);
  }

  async deleteAllNotification() {
    try {
      this.setState({ isClearing: true });
      await this.props.actions.deleteAllNotification();
      this.notificationPopoverRef.current.close();
    } finally {
      this.setState({
        isClearing: false,
        pagination: {
          pageNumber: 1,
          pageSize: this.state.pagination.pageSize,
        },
      });
    }
  }

  async loadMoreNotifications() {
    // frontend pagination, mock network latency
    const { pageNumber, pageSize } = this.state.pagination;
    return new Promise((resolve) =>
      setTimeout(() => {
        this.setState({
          pagination: {
            pageSize: pageSize,
            pageNumber: pageNumber + 1,
          },
        });
        resolve();
      }, 1000),
    );
  }

  getPxInformation() {
    const api = axiosCreate({
      baseURL: '/api/role-permission/business/v1/users',
    }).instance;
    api.get('/pxTenantInfo').then((res) => {
      const pxInfo = res?.data;
      addConfig('pxInfo', pxInfo);
      const myApp = new PXApp();
      myApp.init();
      this.myPXApp = myApp;
    });
  }

  handleSidenav() {
    const { showMenu } = this.props.common;
    showMenu ? this.props.actions.hideMenu() : this.props.actions.showMenu();
    localStorage.setItem('sideMenuManuallyCollapsed', showMenu);
  }

  handleFeedback(e) {
    this.myPXApp && this.myPXApp.openSurvey();
  }

  searchInputRef = React.createRef();
  handleSearchInput = () => {
    const searchInput = this.searchInputRef.current;
    const value = searchInput.value;
    let suggestionItems = [];

    if (value) {
      suggestionItems = searchInfo.searchList.filter((item) => {
        return item.text.toUpperCase().indexOf(value.toUpperCase()) === 0;
      });
    }

    // remove the previous suggestions
    [].slice.call(searchInput.children).forEach((child) => {
      searchInput.removeChild(child);
    });

    // add the new suggestions according to the  user input
    suggestionItems.forEach((item) => {
      const li = document.createElement('ui5-suggestion-item');
      li.text = item.text;
      searchInput.appendChild(li);
    });
  };

  render() {
    const notification = this.props.common.notification;
    
    const { t } = this.props;
    const { pageNumber, pageSize } = this.state.pagination;
    const hasMore = pageNumber * pageSize < notification.length;
    const filteredNotifications = notification.slice(0, pageNumber * pageSize);
    const hasNotification = filteredNotifications && filteredNotifications.length > 0;
    const notificationCount =
      notification.length < MAX_NOTIFICATION_DISPLAY_NUMBER
        ? notification.length
        : `${MAX_NOTIFICATION_DISPLAY_NUMBER - 1}+`;

    return (
      <>
        <ShellBar
          className="shellbar"
          logo={<img alt="SAP Logo" style={{ maxHeight: '2rem' }} src={logo} />}
          primaryTitle={t('ITCM')}
          profile={getAvatar(this.props.user)}
          onProfileClick={this.handleProfileClick}
          showNotifications
          notificationCount={notificationCount}
          onNotificationsClick={this.handleNotificationClick}
        >
          {this.showPXBtnFeatureFlag && (
            <ShellBarItem
              icon="feedback"
              text="Give Feedback to SAP"
              onItemClick={this.handleFeedback}
            />
          )}
        </ShellBar>

        <Popover
          ref={this.notificationPopoverRef}
          allowTargetOverlap
          horizontalAlign={PopoverHorizontalAlign.Center}
          placementType={PlacementType.Bottom}
        >
          <div
            id="_eureka_claims_invoice_list_header_notification_menu"
            key="notifications1"
            className="sap-eureka-common__header__notification"
          >
            {hasNotification && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '5px -7px 10px 0',
                  fontSize: '14px',
                }}
              >
                <div
                  style={{
                    paddingRight: '20px',
                    margin: '0',
                    height: '26px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {this.state.isClearing ? (
                    <BusyIndicator active />
                  ) : (
                    <Button
                      style={{ height: '26px' }}
                      design={ButtonDesign.Default}
                      onClick={this.deleteAllNotification}
                    >
                      {t('notificationToolBar_clear')}
                    </Button>
                  )}
                </div>
              </div>
            )}
            <InfiniteScroll
              onLoadMore={this.loadMoreNotifications}
              hasMore={hasMore}
              height={400}
              style={{
                padding: '0 10px',
                width: hasNotification ? '410px' : '150px',
              }}
            >
              <div
                data-testid="scroll-block"
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
              >
                {hasNotification ? (
                  filteredNotifications.map((filteredNotification) => {
                    return (
                      <CustomNotification
                        key={`notification_${filteredNotification.id}`}
                        notification={filteredNotification}
                        onCloseFunction={this.dismissNotification}
                      />
                    );
                  })
                ) : (
                  <div style={{ padding: '10px 0', textAlign: 'center' }}>
                    {t('Notification_Empty')}
                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </Popover>

        <Popover
          ref={this.profileMenuRef}
          allowTargetOverlap
          horizontalAlign={PopoverHorizontalAlign.Center}
          placementType={PlacementType.Bottom}
        >
          <div>
            <StandardListItem
              data-key="1"
              onClick={this.openProfileSetting}
              icon={'user-settings'}
              infoState="None"
              selected={false}
              type="Active"
            >
              {t('ProfileSettings')}
            </StandardListItem>
            <StandardListItem
              data-key="2"
              onClick={this.logout}
              infoState="None"
              icon={'log'}
              selected={false}
              type="Active"
            >
              {t('Logout')}
            </StandardListItem>
          </div>
        </Popover>

        <Button
          className="toggle-sidenav-btn"
          icon={'menu2'}
          design={ButtonDesign.Transparent}
          onClick={this.handleSidenav}
        />

        <sap-px-feedback id="webComponentFeedbackButton"></sap-px-feedback>

        {/*PX-Invitation dialog*/}
        <dialog id="surveyInvitationDialog" role="dialog">
          <form method="dialog">
            <div className="modal-header">Your Opinion Matters</div>
            <div
              style={{
                marginBottom: '1rem',
                padding: '1rem',
              }}
            >
              Can you help us make SAP software better with your feedback?
            </div>
            <div className="modal-footer">
              <Button
                design={ButtonDesign.Emphasized}
                onClick={() => {
                  this.myPXApp._dialogHandler.provideFeedback();
                }}
              >
                Yes
              </Button>
              <Button
                id="notNow"
                hidden="true"
                onClick={() => {
                  this.myPXApp._dialogHandler.askMeLaterOrNotNow();
                }}
              >
                Not now
              </Button>
              <Button
                id="askMeLater"
                hidden="true"
                onClick={() => {
                  this.myPXApp._dialogHandler.askMeLaterOrNotNow();
                }}
              >
                Ask me later
              </Button>
            </div>
          </form>
        </dialog>
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
